body {
/*  background: #1F98C7;*/
background-image: url("Parchment_1050.jpg");
  padding: 0.5%;
}

p {
  font-size: 16px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 28px;
}



/* ## ## ## Top Bar Components ## ## ## */

.topBarSection {
      width: 70%;
      font-family: Helvetica, arial, sans-serif;
      /*padding: 1px;*/
 /*     padding-bottom: 5px;*/
      /*border: 1px solid #999;*/

      border-bottom: 1px solid grey;
      /*background: white;*/
      /*border-radius: 5px;*/

      position: absolute;
      right: 3%;
      /*top: 1%;*/
      /*margin-top: 2px;*/

      /*position: fixed;*/

      padding-top: 5px;
      padding-bottom: 5px;

      /*position: relative;*/
    }


.brandLogo {
  display: inline;
  width: 45%;
  float: left;
  /*vertical-align: baseline;*/
  margin-bottom: 0px;
/*  padding-bottom: 0px;*/
  /*margin-top: 5px;*/
  border-radius: 5px;
  z-index: 10;
}

.keySelector {
  display: inline;
  /*float: right;*/
  padding-right: 20px;

/*  margin-bottom: 0px;
  padding-bottom: 0px;*/
  /*margin-top: 5px;*/

  position: absolute;
  bottom: 8%;
  right: -1%;
  z-index: 10;
}

.dropdown-menu {
  z-index: 10;
}



.menuIcon {
  float: left;
  display: inline;
  /*width: 120px;*/
  height: 40px;
  /*float: left;*/
  background-color: transparent;
  /*background: rgba(255, 255, 255, 0.4);*/
  font-size: 24px;
  /*color: black;*/
  /*margin-top: 15px;*/
  z-index: 10;
  border: none;
  cursor: pointer;
  text-align: center;

  /*border: 1px solid #999;*/
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;


}

.menuIcon:hover {
background: rgba(255, 255, 255, 0.5);
}



.howToIcon {
  width: 100px;
  position: absolute;
  bottom: 8%;
  left: 45%;
  z-index: 20;
}

.contactIcon {
  width: 100px;
  position: absolute;
  bottom: 8%;
  left: 58%;
}







.clear
{
clear: both;
}

.currentKey {
  /*float: right;*/
  padding-right: 5px;
  padding-top: 0px;
  /*vertical-align: sub;*/
  margin-top: 0px;
  /*vertical-align: text-top;*/
  padding-bottom: 0px;
  margin-bottom: 0px;

  position: absolute;
  top: 0.5%;
  right: 5%;
  z-index: 5;

  font-family: Helvetica, arial, sans-serif;
}








/* ## ## ## Side Bar Components ## ## ## */

.leftSideBar {
  width: 26%;
  height: 1370px;
  font-family: Helvetica, arial, sans-serif;
  padding: 5px;
  padding-bottom: 12px;
  /*border: 1px solid #999;*/
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  margin-top: 2px;
  margin-left: 2px;

  overflow-y: auto;
}

.card {
  width: 100%;
  font-family: Helvetica, arial, sans-serif;
  padding: 5px;
  padding-bottom: 6px;
  /*border: 1px solid #999;*/
  background: rgba(255, 255, 255, 0.7);
  /*border-radius: 10px;*/

}

.catalogue ul {
  padding-left: 0;
}

.catalogue li {
  list-style: none;
}

.songItem {
  margin-bottom: 8px;
  line-height: 1.3;
  padding-left: 5px;
}

.card.loading {
  text-align: center;
}



/* ## ## ## Main Score View Components ## ## ## */

.scoreContainer {
  position: absolute;
  right: 3%;
  top: 11%;
/*  position: fixed;*/
  width: 70%;
  
/*  width: 500px;*/
  height: 1280px;
  /*font-family: Helvetica, arial, sans-serif;*/
  /*margin-top: 3px;*/
  padding: 3px;
  padding-bottom: 3px;
  /*border: 1px solid #999;*/
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;

  /*position: fixed;*/
}

.adUnit {
  width: 70%;
}

.scorePDF {
  text-align: center;
  height: 1100px;
  margin-top: 30px;

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;

  font-family: Helvetica, arial, sans-serif;
  z-index: 20;
}


.miniText {
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.textBack {
  text-align: left;
  position: absolute;
  right: 3%;
  top:1%;
/*  position: fixed;*/
  width: 70%;
  /*margin-top: 30px;*/

  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;

  font-family: Helvetica, arial, sans-serif;
  padding: 20px;
  z-index: 0;
  color: rgba(200,200,200,0);

}




.howToView {
  position: absolute;
  right: 3%;
  top: 11%;
/*  position: fixed;*/
  width: 70%;
  
/*  width: 500px;*/
  height: 1280px;
  font-family: Helvetica, arial, sans-serif;
  padding: 20px;
  padding-bottom: 12px;
  /*border: 1px solid #999;*/
  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;

  /*position: fixed;*/
}





.contactFormEmbed {
  width: 90%;
  height: 959px;
  background: transparent;


}







/* ## ## ## GENERAL PARTS ## ## ## */
/*.alignRight {
  position: absolute;
  right: 3%;
  top: 3%;
}*/

.floatLeft {
  float: left;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  text-align: left;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}


@media only screen and (max-width: 1200px) {
p {
  font-size: 15px;
}

h2 {
  font-size: 26px;
}

.topBarSection {
      width: 72%;
    }
.brandLogo {
  /*width: 50%;*/
}

.menuIcon {
  width: 100px;
  height: 40px;
  font-size: 22px;
  /*margin-top: 15px;*/
/*  border: 1px solid #999;
  border-radius: 5px;*/
}

.howToIcon {
  /*left: 48%;*/
}

.contactIcon {
  left: 60%;
}



.leftSideBar {
  width: 24%;
}

/*FOR TEST PURPOSE*/
.card {
  /*background-color: red;*/
}

.songItem {
/*  margin-bottom: 8px;
  line-height: 1.3;
  padding-left: 5px;
*/}

.scoreContainer {
  /*position: absolute;*/
  /*right: 3%;*/
  top: 9%;
/*  position: fixed;*/
  /*width: 72%;*/
  
/*  width: 500px;*/
  /*height: 1200px;*/
  /*font-family: Helvetica, arial, sans-serif;*/
  padding: 5px;/*
  padding-bottom: 12px;*/
  /*border: 1px solid #999;*/
/*  background: rgba(255, 255, 255, 0.4);
  border-radius: 10px;*/

  /*position: fixed;*/
}

.adUnit {
  width: 72%;
}

.scorePDF {
  /*text-align: center;*/
  /*height: 1100px;*/
  /*margin-top: 30px;*/

/*  display: block;
  margin-left: auto;
  margin-right: auto;*/
  width: 100%;

  /*font-family: Helvetica, arial, sans-serif;*/
}

}


@media only screen and (max-width: 1000px) {
p {
  font-size: 14px;
}

h3 {
  font-size: 23px;
}

.topBarSection {
      width: 74%;
      margin-right: 0px;
    }

.brandLogo {
  /*width: 40%;*/
  /*margin-top: 10px;*/
}

.menuIcon {
  width: 80px;
  height: 35px;
  font-size: 20px;
  /*margin-top: 12px;*/
/*  border: 1px solid #999;
  border-radius: 5px;*/

}

.howToIcon {
  /*left: 40%;*/
}

.contactIcon {
  /*left: 56%;*/
}





.leftSideBar {
  width: 22%;
}

/*FOR TEST PURPOSE*/
.card {
  /*background-color: lightblue;*/
}

.songItem {
  margin-bottom: 6px;
  line-height: 1.3;
  padding-left: 3px;
}


.scoreContainer {

  /*right: 3%;*/
  top: 8%;

  width: 74%;
  
  /*width: 500px;*/

/*  padding: 5px;
  padding-bottom: 12px;*/

  margin-right: 0px;
  padding: 2px;

}

.adUnit {
  width: 74%;
}

.scorePDF {

  width: 100%;

}



}

@media only screen and (max-width: 800px) {

h3 {
  font-size: 21px;
}

.brandLogo {
  width: 35%;
  /*margin-top: 10px;*/
}

.menuIcon {
  width: 65px;
  height: 30px;
  font-size: 16px;

}

.howToIcon {
  left: 35%;
}

.contactIcon {
  left: 50%;
}


/*FOR TEST PURPOSE ONLY*/
.card {
  /*background-color: orange;*/
}


}




